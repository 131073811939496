import React, { useState } from "react";
import classNames from "classnames";
import MyModal from "../MyModal";

const SelectDropdown = ({
  label,
  value,
  placeholder,
  options,
  onSelectChange,
  noBorder,
  labelKey = "name",
  valueKey = "id",
  disabled,
  error,
  errorMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={"common_kupos_select_box"}>
      {label && (
        <div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "white",
              fontSize: 14,
            }}
          >
            {label}
          </div>
        </div>
      )}
      <div
        className={classNames({
          ["select_box"]: true,
          ["border"]: !noBorder,
        })}
        onClick={() => setShowModal(true)}
      >
        {value && value[labelKey] ? (
          <div className={"selected_value"}>{value[labelKey]} </div>
        ) : (
          <div className={"placeholder"}>{placeholder || "Select Date"}</div>
        )}
        <img
          src="/images/right-arrow.svg"
          style={{ transform: "rotate(90deg)", marginLeft: 10 }}
          alt=""
        />
      </div>
      {showModal && (
        <MyModal
          open={showModal}
          title={label || "Select date to view the epaper"}
          // desc={currentItem && currentItem.description}
          onClose={() => setShowModal(false)}
        >
          <div className={"select_options_container"}>
            {(options || []).map((option) => (
              <div
                className={classNames({
                  ["select_option_item"]: true,
                  // [classes.selected_option]: value && option[valueKey] == value[valueKey]
                })}
                onClick={() => {
                  onSelectChange(option);
                  setShowModal(false);
                }}
              >
                {option.icon && <img src={option.icon} />}
                <span>{option[labelKey]}</span>
              </div>
            ))}
          </div>
        </MyModal>
      )}

      {error && (
        <div style={{ marginTop: 10 }}>
          {error ? (
            <span className="font11 red-font">{errorMessage}</span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
