import { Modal } from "rsuite";

const MyModal = (props) => {
  console.log({ props });
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={props.open}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Modal.Title id="modal-title">{props.title}</Modal.Title>
      </Modal.Header>

      {props.desc && (
        <Modal.Body id="modal-description">
          <span dangerouslySetInnerHTML={{ __html: props.desc }}></span>
        </Modal.Body>
      )}

      {props.children}
    </Modal>
  );
};
export default MyModal;
