import React, { useEffect, useState } from "react";
import MultilevelDropdown from "./MultilevelDropdown";
import { useHistory } from "react-router-dom";
import SelectDropdown from "./select-dropdown/select-dropdown";
import { allPostsState, currentEpaperState } from "../Recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import DateService from "../services/dateService";

function Header(props) {
  let history = useHistory();

  const setCurrentEpaper = useSetRecoilState(currentEpaperState);
  const allPosts = useRecoilValue(allPostsState);
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const onDateChange = (val) => {
    setSelectedItem(val);
    if (allPosts && allPosts[val.id]) {
      setCurrentEpaper(allPosts[val.id]);
    }
  };

  useEffect(() => {
    let localOptions = [];

    allPosts?.forEach((post, index) => {
      let date = DateService.changeDateFormat(
        post.PostingDate?.split(" ")[0],
        "yyyy-mm-dd",
        "dd-mm-yyyy"
      );
      localOptions.push({
        id: index,
        label: date,
        name: date,
      });
    });

    setOptions(localOptions);
    setSelectedItem(localOptions[0]);
  }, [allPosts]);

  return (
    <div className="header-parent">
      <div className="logo-conntainer" onClick={() => history.push("/")}>
        <img src="/images/logosr.png" alt="logo" />
      </div>
      <div className="links-container font15">
        <MultilevelDropdown />
      </div>

      <div className="date_selection_dropdown">
        <SelectDropdown
          placeholder={"View old date epaper"}
          options={options}
          value={selectedItem}
          onSelectChange={(val) => onDateChange(val)}
          // label="View old date epaper"
        />
      </div>
    </div>
  );
}

export default Header;
