import React from "react";
import { useHistory } from "react-router-dom";
import { FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";

function Footer(props) {
  const history = useHistory();
  const navigateRoute = (screen) => {
    history.push(screen);
  };

  return (
    <div className="footer-parent-container">
      <div className="footer-container-inner">
        <div className="footer-item">
          <div>
            <div className="footer-item-left bold-text">Location:</div>
            <div className="footer-item-left">{"Pulwama Kashmir, 192301"}</div>
          </div>
        </div>

        {/* <div className="footer-item">
          <div className="link">
            <span onClick={() => navigateRoute("/feedback")}>About us</span>
          </div>
          <div className="link">
            <span onClick={() => navigateRoute("/contact-us")}>Contact us</span>
          </div>
        </div> */}
        {/* <div className="footer-item">
          <div className="bold-text">Around the Web</div>
          <div className="social-icons">
            <span
              onClick={() =>
                window.open(
                  props.data.facebook || "https://www.facebook.com",
                  "_blank"
                )
              }
            >
              <FaFacebook fontSize={30} />
            </span>
            <span
              onClick={() =>
                window.open(
                  props.data.youtube || "https://www.youtube.com",
                  "_blank"
                )
              }
            >
              <FaYoutube fontSize={30} />
            </span>
          </div>
        </div> */}
      </div>

      <div class="footer-info">
        <div class="copyright-info">
          <span className="font10">
            © Srinagar Reporter {new Date().getFullYear()}, All Rights Reserved
          </span>
        </div>
        <div class="company-info">
          <a href="http://bracecodes.in">
            <span class="design-and-mantain font10">
              Designed and Maintained with{" "}
              <span style={{ color: "red" }}>&#9829;</span> By Bracecodes
              Software Solutions Pvt. Ltd.
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
